import '../assets/css/nds.css';
import React, { Component } from "react"
import conIcon from "../images/cong-icon.svg"
import Layout from "../components/layout"
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';


class ndsend extends Component {
    constructor(props) {
        super(props);
        this.state = {};
       
      }
    
    componentDidMount() {
        this.nameInput.focus();
    }
    render() {
        return (
            <Layout layoutType="nds" >
                <input className="hidox" ref={(input) => { this.nameInput = input; }} />
                <section className="congratulation-section ndsec">
                    <div className="custom-container main-congratulation-container">
                        <div className="body-content congratulation-content ">
                            <div className="congratulation-container ">
                            <img src="https://dowc-asserts.s3.amazonaws.com/keyfetchlogo.jpg" alt=""  />
                            <h4>You have been successfully registered.</h4>
                        </div>
                    </div>
                </div> 
                </section>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ndsend);
